/* You can add global styles to this file, and also import other style files */
@import "src/assets/styles/colors";

// font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

// reset styles

body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  overflow: hidden;
}

* {
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Roboto', sans-serif;
  word-break: break-word;
  outline: none;
  box-sizing: border-box;
}

a {
  color: #3c3c3c;
  text-decoration: none;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;
}

input {
  padding: 0;
  border: 0;
  margin: 0;
}

fieldset {
  padding: 0;
  display: block;
  border: none;
  background: none;
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  color: unset;
  margin: unset;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  text-align: unset;
  padding: 0;
  border: 0;
  cursor: pointer;
  &.disabled {
    cursor: default !important;
    background: #F3F3F3!important;
    border: none !important;
    color: #666666 !important;
    &:hover {
      color: #666666 !important;
      background: #F3F3F3 !important;
      border: none !important;
      opacity: 1 !important;
    }
  }
}

body {
  overflow-y: scroll !important;
  &::-webkit-scrollbar {
    display: none;
  }
}
